@import url('https://fonts.googleapis.com/css2?family=Exo+2&family=Titan+One&display=swap');


.header {
   color: rgb(109, 0, 0);
   text-align: center;

   background-size: cover;
   font-family: 'Permanent Marker', cursive;
}
.header:hover {
    color: rgb(77, 0, 0);
}

.card{
    font-family: 'Exo 2', sans-serif;
    box-shadow: 1 6px 8px 0 rgba(0,0,0,0.2)!important;
    transition: 0.3s;
    border: solid rgba(70, 70, 70, 0.205)!important;
}
.card:hover{
    font-family: 'Exo 2', sans-serif;
    color:rgb(95, 0, 0);
    background-color: rgb(216, 216, 216)!important;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2)!important;
    
}

.card>img{
  border: solid rgba(114, 69, 69, 0.363)!important;
  border-radius: 20px;
}



#details_page_review_section{
    font-family: 'Exo 2', sans-serif;
    box-shadow: 16px 8px 16px 0px rgba(0,0,0,0.2)!important;
    min-height:100vh;
    height: 100%;
    border: solid rgb(216, 216, 216);
    border-radius: 25px;
}

.restaurantName {
    font-family: 'Titan One', cursive;
    text-align: center;
    
}

.RestaurantRating{
    font-size: 1.5rem;
}

body{
    font-family: 'Exo 2', sans-serif;
    background-image: linear-gradient(to bottom, rgba(216, 216, 216, 0.767) , rgb(255, 255, 255)); 
    background-repeat: no-repeat;
   
  
}

/* #navbar{
    color: rgb(109, 0, 0);
} */

.Locations, .addRestaurant{
    margin-top: 10px;
}

.toastAddressPhone {
    font-family: 'Exo 2', sans-serif;
    margin-top: 5%;
    margin-bottom: 5%;
    text-align: center;

}

.toast-header, .toast-body {
    font-family: 'Exo 2', sans-serif;
    font-size: 1rem;
    
}

svg {
    margin-right: 1%;
}

.card-title {
    font-size: 1.5rem;
}

.card hr {
    margin-top: 0rem;
    margin-bottom: .5rem;
}

#reviewFooter {
    font-size: x-small;
    text-align: center;
    font-style: italic;
}



/* @media only screen and (min-width: 990px) {

  } */

/* App.css */
.image-carousel {
    display: flex;
    flex-wrap: wrap;
  }
  
  .image-wrapper {
    cursor: pointer;
    margin: 5px;
  }
  
  .image-wrapper img {
    max-width: 100px;
    max-height: 100px;
  }
  
  .fullscreen-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .fullscreen-overlay img {
    max-width: 90%;
    max-height: 90%;
  }
  


#upload-images-button {
    height: 50px;
    width: 50px;
    padding: 0px;

}

.dropzone {
    display: inline-block;
}


  .navbar {
    background-color: transparent; /* Set the background color to transparent */
    position: fixed; /* Fixed position to make it sticky at the top */
    width: 100%; /* Full width */
    top: 0; /* Stick it to the top of the viewport */
    z-index: 100; /* Ensure it's above other content */
    display: flex;
    justify-content: space-between;
    padding: 10px 20px; /* Add padding as needed */
  }
  
  .nav-links {
    list-style: none;
    display: flex;
    gap: 20px;
    margin: 0;
    padding: 0;
  }
  
  .nav-links li {
    color: white; /* Set the text color to contrast with the background */
    cursor: pointer;
    font-size: 18px;
    font-weight: bold;
    text-decoration: none;
    transition: color 0.3s; /* Add a smooth transition for color change */
  }
  
  .navbar-toggler {
    border: none!important;
    border-color: rgba(0,0,0,0)!important;
  
  }

  .image-upload>input {
      display: none;
  }

  #search-bar{
    border-radius: 25px;
  }